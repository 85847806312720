.form_send_message {
    padding: 20px;
    display: flex;
    /* gap: 10px; */

        .input_chat {
            flex: 1 1 auto;
        
            padding: 13px;
            outline: none;
            border-radius: 10px 0 0 10px;
            border: none;
            background: #f3f6fe;
        
            font-size: 16px;

            &:focus {
                transition: all ease 100ms;
                box-shadow: 1px 1px 20px #edf0f8;
                cursor: pointer;
            }
            
        }

        .btn_send_message {
            padding: 13px 15px;
            border: none;
            background: #4f7ef5;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 0 10px 10px 0;

            &:hover {
                cursor: pointer;
            }            

            .icon_sendmessage {
                width: 20px;
                fill: #FFFF;        
            }
        }
}