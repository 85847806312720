.info_curent_chat {
    display: flex;
    padding: 20px;
    gap: 20px;

    .profile_current_chat {
        position: relative;

        .image_my_profile {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            object-fit: cover;          
        }

        .user_connected {
            position: absolute;
            right: 0;
            top: 0;
            width: 11px;
            height: 11px;
            border-radius: 100%;
            background: #07b307;
            border: 2px solid #FFFF;
        }
        
    }

    .arrow_left {
        width: 25px;
        fill: #acacac;

        @media screen and (min-width: 700px) {
            display: none;
        }

    }
}

.info_connection_state {
    color: #c4c4c4;
    font-size: 14px;
}

.full_name {
    color: #acacac;
    font-weight: 700;
}