* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    outline: none;
    background: #000000;
    font-family: var(--font-poppins);
}

input {
    font-family: var(--font-poppins);
}