.loader {
    /* width: 48px; */
    width: 100%;
    /* height: 48px; */
    height: 100%;
    border: 3px solid #4f7ef5;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
    
        100% {
            transform: rotate(360deg);
        }
    }
}

