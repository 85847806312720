.center_main {
    max-width: var(--max-width-modules);
    height: 100vh;
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    .login {
        /* min-width: 400px; */
        /* padding: 20px; */
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include shadow_box;

        .google_login {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 20px;

            .information_login {
                .subtitle {
                    font-weight: 700;
                    font-size: 25px;
                    color: #292929;
                }
                
                .info {
                    font-size: 12px;
                    color: #878991;
                }
                
            }

            .google_button {
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                .loader_ {
                    width: 100%;
                    height: 100%;
                    position: absolute;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background: #FFFF;
                    border: 1px solid rgba(0, 0, 255, 0.116);
                    border-radius: 4px;

                    .loader_sing_in {
                        width: 20px;
                        height: 20px;
                        // top: 0;
                    }
                }
                
            }

        }

        .test_login {

            .registry {
                padding: 20px; 
               /* background: #f5f5f5; */ 
               border-top: 1px solid #f1f1f1;
           
               display: flex;
               flex-direction: column;
               gap: 10px;


               .btn_login {
                padding: 13px 15px;
                border: none;
                background: #4f7ef5;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                color: #FFFF;
            }
           }
        }
    }
}







