.main_chat {
    /* height: 100vh; */
    padding: 20px;

    display: flex;
    // flex-direction: column;
    height: 100vh;
    gap: 20px;



    .sidebar_chat {
        width: 30%;
        display: flex;
        flex-direction: column;
        // gap: 20px;

        .select_chat {
            margin: 10px 0 0 0;

            .video:empty {
                width: 100%;
                height: 70px; 
                margin: 0 0 10px;
                
                cursor: progress; 

                border-radius: 20px;

                
                background: 
                    #fcfcfc
                  linear-gradient(0.25turn, transparent, #f5f5f5, transparent),
                ;
                background-repeat: no-repeat;
                background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; 
                background-position: s-315px 0, 0 0, 0px 190px, 50px 195px; 
                animation: loading 1.5s infinite;

                @keyframes loading {  
                    to {
                      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
                    }
                  }
              }


        }

        @media screen and (max-width: 700px) {
            width: 100%;
        }



        .header_profile {
            padding: 17px;

            display: flex;
            flex-direction: column;
            /* gap: 20px; */
            transition: all ease-in-out 1s;
            @include shadow_box;
        }

    }

    .messages {
        width: 70%;
        display: flex;
        flex-direction: column;
        @include shadow_box;

        @media screen and (max-width: 700px) {
            display: none;
        }

        .information_app {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 20px;
        }

        .icons_app {
            display: flex;
            gap: 30px;

            .icon_nodejs {
                width: 5em;
                fill: #ebebeb;
            }

            .icon_socketio {
                width: 5em;
                fill: #ebebeb;
            }
        }

    }

    .ocult {
        display: none;

    }

    .show {
        display: block;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    @media screen and (min-width: 700px) {
        .ocult {
            display: block;
        }

        .show {
            display: none;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

}