.my_chats {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .search_more_people {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .btn_search_people {
            appearance: none;
            background-color: transparent;
            border: 3px solid #4f7ef5;
            border-radius: 15px;
            box-sizing: border-box;
            color: #3B3B3B;
            cursor: pointer;
            display: inline-block;
            /* font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"; */
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            margin: 0;
            min-height: 60px;
            min-width: 0;
            outline: none;
            padding: 16px 24px;
            text-align: center;
            text-decoration: none;
            transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            /* width: 100%; */
            will-change: transform;
            color: #4f7ef5;
        
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            &:disabled {
                pointer-events: none;
            }
            
            &:hover {
                color: #fff;
                background-color: #4f7ef5;
                box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
                /* transform: translateY(-2px); */
            }
            
            &:hover>.btn_search_icon {
                fill: #fff;
                transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
            }
            
            &:active {
                box-shadow: none;
                transform: translateY(0);
            }
            
            .icon_add_people {
                width: 20px;
                fill: #4f7ef5;
            }
            
            .btn_search_icon {
                width: 20px;
                fill: #4f7ef5;
            }
        }
    }
}