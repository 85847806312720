.chat_messages {
    /* background-image: url('https://i.pinimg.com/originals/8c/98/99/8c98994518b575bfd8c949e91d20548b.jpg');
    background-size: 100% 100% cover; */

    /* background-repeat: no-repeat; */
    /* background-position: cover; */

    flex: 1 1 auto;
    padding: 20px 30px;
    background: #f7f7f7;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 10px;

    .brefore_sending_message {
        flex: 1 1 auto;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        padding: 0 0 20px 0;

        .title_before_sending {
            color: #858585;
            font-size: 20px;
            font-weight: 700;
        }

        .message_before_sending {
            color: #858585;
            font-size: 15px;
        }
    }

    .message_order {
        display: flex;
        /* max-width: 300px; */
    }


    .in {
        justify-content: flex-start;

    }

    .in div:first-child {
        background: #ebebeb;
        color: #727272;

    }

    .out {
        justify-content: flex-end;
        position: relative;
    }
}