.chat_card {
    padding: 15px;

    display: flex;
    gap: 15px;
    @include shadow_box;
}

.chat_card:hover {
    transition: all ease 500ms;
    box-shadow: 1px 1px 20px #e0e4f0;
    cursor: pointer;
}

.user_profile {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
}

.user_connected {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background: #07b307;
    border: 2px solid #FFFF;
}

.user_disconnected {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background: #c50808;
    border: 2px solid #FFFF;
}

.image_profile {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    overflow: hidden;
    border: 2px solid #e7ecf3;
}

.image_profile>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.information_room {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
}

.date_last_connected {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
}

.unread_messages {
    width: 10px;
    height: 10px;
    padding: 10px;
    border-radius: 100%;
    background: #d73260;
    color: #FFFF;
    text-align: center;
    font-size: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.last_connected {
    color: #a6afc5;
    font-size: 10px;
}

.user_name {
    color: #010e69;
    font-weight: 700;
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.last_message {
    color: #a9b0c5;
    font-size: 13px;
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.new_message {
    font-size: 12px;
    color: red;
}

.new_message_ {
    font-weight: 600;
    color: #c8cddb;
}
